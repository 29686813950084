import {Stack} from '@mui/material'
import React, {useState} from 'react'
import MainBtn from '../../customTable/MainBtn'
import PopUp from '../../customTable/PopUp'
import Label from '../../customTable/Label'
import PayForm from './PayForm'
import PackagesApi from '../../../Api/Packages'
import Varefacation from '../Varefacation'

export default function RowBtn({index, prices, value, duration, isRenew, name, type}: any) {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isVaravacation, setIsVaravacation] = useState(false)
  const [signature, setSignature] = useState('')
  console.log(duration, 'duration')

  const submit = () => {
    if (isRenew) {
      PackagesApi.renew((res: any) => {
        setIsVaravacation(true)
      }, setIsLoading)
    } else {
      PackagesApi.upgrade(
        {
          plan_id: index,
          plan_type: type[index],
        },
        (res: any) => {
          setSignature(res.signature)
          setOpen(false)
          setIsVaravacation(true)
        },
        setIsLoading
      )
    }
  }
  return (
    <Stack>
      <MainBtn
        handelSubmit={() => {
          setOpen(true)
          console.log(index, prices[index], duration[index])
        }}
        br='8px'
        width='101px'
        bgcolor={value}
        text='getStart'
      />
      <PopUp width='xs' open={open} setOpen={setOpen}>
        <PayForm
          isLoading={isLoading}
          submit={submit}
          duration={duration[index]}
          price={prices[index]}
          name={name[index]}
          type={type[index]}
        />
      </PopUp>
      <PopUp width='xs' open={isVaravacation}>
        {isVaravacation && (
          <Varefacation
            type={type[index]}
            signature={signature}
            isRenew={isRenew}
            price={prices[index]}
            name={name[index]}
            id={index}
            setOpen={setIsVaravacation}
            open={isVaravacation}
          />
        )}
      </PopUp>
    </Stack>
  )
}
