import React, {useRef} from 'react'

export default function InputCode({codeValue, setCodeValue, e, isfocus, err}: any) {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCodeValue({...codeValue, [`${e}`]: event.target.value})

    if (event.target.value === '' && inputRef.current?.previousSibling instanceof HTMLElement) {
      inputRef.current.previousSibling.focus()
    } else if (inputRef.current?.nextSibling instanceof HTMLElement) {
      inputRef.current.nextSibling.focus()
    }
  }

  return (
    <>
      <input
        ref={inputRef}
        style={{
          width: '60px',
          height: '60px',
          borderRadius: '6px',
          fontSize: '26px',
          color: '#7E8299',
          textAlign: 'center',
          border: `1px solid ${err ? 'red' : '#e1e3ea'}`,
        }}
        type='text'
        value={codeValue[`${e}`]}
        maxLength={1}
        onChange={handleInputChange}
        className='input-code'
        autoFocus={e === isfocus}
      />
    </>
  )
}
