import {CircularProgress, Container, Stack, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'
import OptionBtns from './OptionBtns'
import ShowPotion from './ShowPotion'
import Variations from '../variations/Variations'
import VariationsApi from '../../../../../Api/variations'
import {useParams} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Product from '../../../../../Api/product'
import {toast} from 'react-toastify'
import MainBtn from '../../../../customTable/MainBtn'
import WarperBackages from '../../../../customTable/WarperBackages'
type Props = {
  formik: any
  setActiveKey: React.Dispatch<React.SetStateAction<string>>
  setActive: React.Dispatch<React.SetStateAction<number>>
  activeKey: string
}
export default function Options(props: Props) {
  const {formik, setActiveKey, setActive, activeKey} = props
  const handelNext = async () => {
    // setActive(3)
    setActiveKey('Bulk_Discount')
  }
  const {id} = useParams()
  const [isActive, setIsActive] = useState(0)
  const [isCreate, setIsCreate] = useState<any>('')
  const [isActiveBtn, setIsActiveBtn] = useState(0)
  const [isDeleted, setIsDeleted] = useState(false)
  const [selectedOption, setSelectedOption] = useState([])
  const [optionValue, setOptionValue] = useState<any>([])
  const [isAddedOption, setIsAddedOption] = useState(true)
  const [values, setValues] = useState({
    is_require: 1,
    is_upload_image: 0,
    is_main_image: 1,
    option_id: null,
    option: {},
  })
  useEffect(() => {
    if (values.option) {
      setIsCreate(values.option)
    }
  }, [values.option_id])
  const [isNew, setIsNew] = useState(true)
  const [optionsBtns, setOptionsBtns] = useState([{id: -1}])
  const [loadingShowOption, setLoadingOption] = useState(false)
  const [activeDataBtn, setActiveDataBtn] = useState(0)
  const [data, setData] = useState([])
  const [isLoadingVariations, setIsLoadingVariations] = useState(false)

  const getVariations = async () => {
    const variationsData = await VariationsApi.getVariations(
      id,
      (res: any) => {
        setData(res.data.product_variations)
      },
      setIsLoadingVariations
    )
  }
  const [isLoadingOptions, setIsLoadingOptions] = useState(false)
  useEffect(() => {
    if (activeKey === 'Options') {
      getVariations()
    }
  }, [activeKey, optionsBtns])
  const {t} = useTranslation()
  const [isActiveAddBtn, setIsActiveAddBtn] = useState(false)
  const submit = async (setLoading: any, values: any, setOptionValue: any, setMsgErr: any) => {
    try {
      setLoading(true)

      if (isCreate.input_type === 'single_select' || isCreate.input_type === 'multi_select') {
        console.log('here')

        if (values.id) {
          console.log(optionValue, 'optionValue')

          Product.editOption(
            values.id,
            {
              ...values,
              product_option_id: values.id,
              product_id: id,
              is_main_image: values.is_main_image,
              option_values: optionValue.length === 0 ? null : optionValue,
              is_require: values.is_require ? values.is_require : 0,
            },
            (res: any) => {
              setValues({
                ...res,
                isDefult: res.product_option_values?.find((e: any) => e.is_default === 1)?.id,
                // ?.find((e: any) => e.is_default === 1)?.id,
              })
              setLoading(false)

              setOptionValue([])
              setMsgErr('')
            },
            setLoading
          )
        } else {
          if (optionValue.length > 0) {
            Product.storeOption(
              {
                ...values,
                option_id: isCreate.id,
                product_id: id,
                is_main_image: values.is_main_image,
                option_values: optionValue.map((e: any) => {
                  if (e.idDate === values.isDefult) {
                    return {...e, is_default: 1}
                  } else {
                    return {...e, is_default: 0}
                  }
                }),
              },
              (res: any) => {
                setLoading(false)
                setValues({
                  ...res,
                  isDefult: res.product_option_values?.find((e: any) => e.is_default === 1)?.id,
                  // ?.find((e: any) => e.is_default === 1)?.id,
                })
                // toast.success(t('Added successfully'))

                setOptionsBtns([...optionsBtns, {id: res.id}])
                setOptionValue([])
                setActiveDataBtn(optionsBtns.length)
                setIsActiveBtn(optionsBtns.length)
                setMsgErr('')
                setIsAddedOption(true)
              },
              setLoading
            )
          } else {
            setMsgErr('add some items!')
            setLoading(false)
          }
        }
      } else {
        if (values.id) {
          Product.editOption(
            values.id,
            {
              ...values,
              option_id: isCreate.id,
              product_id: id,
              is_main_image: 0,
              product_option_id: values.id,

              option_values: optionValue,
              is_require: values.is_require ? values.is_require : 0,
            },
            (res: any) => {
              setValues(res)

              setMsgErr('')
            },
            setLoading
          )
        } else {
          Product.storeOption(
            {
              ...values,
              option_id: isCreate.id,
              product_id: id,
              is_main_image: 0,
              option_values: optionValue,
              is_require: values.is_require ? values.is_require : 0,
            },
            (res: any) => {
              setIsActiveBtn(optionsBtns.length)
              setActiveDataBtn(optionsBtns.length)
              setValues(res)

              setOptionsBtns([...optionsBtns, {id: res.id}])
              setOptionValue([])
              setIsAddedOption(true)

              setMsgErr('')
            },
            setLoading
          )
        }
      }
    } catch (err: any) {
      toast.error(err.response.data.message)

      setLoading(false)
    }

    // // setLoading(false)
    // setIsCreate('')
  }
  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoading, setLoading] = useState(false)
  const [isSelected, setIsSelected] = React.useState<number[]>([-2])

  return (
    <>
      <Stack
        width={'100%'}
        sx={{padding: '35px 29.5px ', borderRadius: '8px', background: 'var(--bs-body-bg)'}}
      >
        <Stack sx={{}} gap='32px' direction={'row'}>
          <Typography
            onClick={() => {
              setIsActive(0)
              setIsAddedOption(true)
            }}
            sx={{
              fontSize: '18px',
              fontWeight: '500',
              textTransform: 'capitalize',
              lineHeight: '27px',
              color: isActive === 0 ? 'var(--main-color)' : 'auto',
              borderBottom: isActive === 0 ? '1px solid var(--main-color)' : 'none',
              paddingBottom: '5px',
              cursor: 'pointer',
            }}
          >
            {t('option')}({`${optionsBtns.length}`})
          </Typography>

          <Typography
            onClick={() => {
              setIsActive(1)
            }}
            sx={{
              fontSize: '18px',
              fontWeight: '500',
              textTransform: 'capitalize',
              lineHeight: '27px',
              color: isActive === 1 ? 'var(--main-color)' : 'auto',
              borderBottom: isActive === 1 ? '1px solid var(--main-color)' : 'none',
              paddingBottom: '5px',
              cursor: 'pointer',
            }}
          >
            {t('variations')} ({`${data.length}`})
          </Typography>
        </Stack>
        {isActive === 0 ? (
          <OptionBtns
            activeDataBtn={activeDataBtn}
            isActive={isActiveBtn}
            setIsActive={setIsActiveBtn}
            setValues={setValues}
            setSelectedOption={setSelectedOption}
            setIsCreate={setIsCreate}
            setIsNew={setIsNew}
            optionsBtns={optionsBtns}
            setOptionsBtns={setOptionsBtns}
            setLoadingOption={setLoadingOption}
            setOptionValue={setOptionValue}
            isDeleted={isDeleted}
            setIsDeleted={setIsDeleted}
            activeKey={activeKey}
            setIsActiveAddBtn={setIsActiveAddBtn}
            setIsAddedOption={setIsAddedOption}
            isAddedOption={isAddedOption}
            submit={submit}
            values={values}
            msgErr={msgErr}
            setMsgErr={setMsgErr}
            isLoading={isLoading}
            setLoading={setLoading}
          />
        ) : (
          <WarperBackages width backageKey='PRODUCT_VARIATION_NUM'>
            <Variations data={data} setData={setData} />
          </WarperBackages>
        )}
      </Stack>{' '}
      {isActive === 0 ? (
        <Stack
          sx={{
            padding: '35px 29.5px',
            borderRadius: '8px',
            background: 'var(--bs-body-bg)',
            marginTop: '24px',
          }}
        >
          {loadingShowOption ? (
            <CircularProgress />
          ) : (
            <ShowPotion
              isSelected={isSelected}
              setIsSelected={setIsSelected}
              values={values}
              setValues={setValues}
              setIsCreate={setIsCreate}
              selectedOption={selectedOption}
              isCreate={isCreate}
              setOptionValue={setOptionValue}
              optionValue={optionValue}
              isNew={isNew}
              setIsNew={setIsNew}
              optionsBtns={optionsBtns}
              setOptionsBtns={setOptionsBtns}
              setIsActiveBtn={setIsActiveBtn}
              isActiveBtn={isActiveBtn}
              setActiveDataBtn={setActiveDataBtn}
              setIsDeleted={setIsDeleted}
              setLoadingOption={setLoadingOption}
              isActiveAddBtn={isActiveAddBtn}
              submit={submit}
              setIsAddedOption={setIsAddedOption}
              msgErr={msgErr}
              setMsgErr={setMsgErr}
              isLoading={isLoading}
              setLoading={setLoading}
              isAddedOption={isAddedOption}
              setSelectedOption={setSelectedOption}
              setIsActiveAddBtn={setIsActiveAddBtn}
              setIsActive={setIsActiveBtn}
            />
          )}
        </Stack>
      ) : (
        ''
      )}
      <div style={{marginTop: '32px', gap: '20px'}} className='d-flex justify-content-end'>
        <button
          className='btn btn-light me-5'
          onClick={() => {
            setActiveKey('Media')
          }}
        >
          {t('back')}
        </button>
        <MainBtn
          width='auto'
          isLoading={formik.isSubmitting}
          handelSubmit={handelNext}
          text='next'
        />
        {/* <button type='button' className='btn btn-danger' onClick={handelNext}>
          <span className='indicator-label'>{t('next')}</span>
          {formik.isSubmitting && (
            <span className='indicator-progress'>
              {t('wait')}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button> */}
      </div>
    </>
  )
}
