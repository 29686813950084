import {Suspense, useState} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import ScrollToTop from './componet/scrollTop'
import {AuthInit} from './modules/auth'
import {useEffect} from 'react'
import './fonts/en/web/inter.css'
import './App.css'
import Langs from './Api/SettingLangs'
import {useDispatch} from 'react-redux'
import {actions} from './store/vender/Auth/auth.slice'
import {useSelector} from 'react-redux'
import {RootState} from './store'
import {Stack} from '@mui/material'
import Login from './modules/login'
import JwtService from './utils/TokenServices'
import InvoEn from './modules/orders/View/InvoEn'
import Loading from './componet/loading'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useTranslation} from 'react-i18next'
import RolesPermissions from './Api/rolesPermissions'
const App = () => {
  const location = useLocation()

  const dispatch = useDispatch()
  const store = useSelector((state: RootState) => state.vender)
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const {i18n} = useTranslation()
  const [isLoadingPer, setIsLoadingPer] = useState(false)
  const [isLoadingPackage, setIsLoadingPackage] = useState(false)

  useEffect(() => {
    localStorage.setItem('kt_theme_mode_value', 'light')
    localStorage.setItem('kt_theme_mode_value2', 'light')
  }, [])
  const getLangs = () => {
    Langs.getselectedLang((res: any) => {
      localStorage.setItem('langs', JSON.stringify(res.data.store_languages))
      const langsCode = res.data.store_languages.map((e: any) => e.code)
      dispatch(actions.setStoreLangs(langsCode))
      dispatch(actions.setDefLang(res.data.default_language.code))
      dispatch(actions.setLang(res.data.default_language.code))
      dispatch(actions.setLangs(res.data.store_languages))

      dispatch(actions.setLangStatus(res.data.default_language_status))
      dispatch(actions.setRtl(res.data.default_language.is_rtl))
    }, setIsLoading)
    RolesPermissions.getMyPermissions((res: any) => {
      dispatch(actions.setPermissions(res.data))
    }, setIsLoadingPer)
    RolesPermissions.getMyPackageFeatures((res: any) => {
      let packages: any = {}
      res.data.map((e: any) => {
        packages[`${e.feature_key}`] = e
      })
      console.log(packages, 'packages')

      dispatch(actions.setMyPackage(packages))
    }, setIsLoadingPackage)

    if (store.user && location.pathname === '/login') {
      navigate('/dashboard')
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
    dispatch(actions.getStoreImage(localStorage.getItem('storeImage')))
    dispatch(actions.getDomain(localStorage.getItem('domain')))
  }
  useEffect(() => {
    if (store.user) {
      getLangs()
    }
  }, [store.user])
  useEffect(() => {
    if (
      location.pathname.includes('/catalog/sub-category') &&
      location.pathname.includes('/edit-category')
    ) {
      navigate(`/catalog/sub-category/${location.pathname.split('/')[3]}`)
    } else if (location.pathname.includes('/add-category/add-sub-category')) {
      navigate(`/catalog/add-sub-category`)
    }
  }, [location])
  return (
    <>
      <ToastContainer />
      <Stack
        // sx={{
        //   fontFamily:
        //     store.isRtl !== 0
        //       ? 'Inter, sans-serif !important'
        //       : 'Readex Pro, sans-serif !important',
        // }}
        lang={store.lang}
        overflow={isLoading ? 'hidden' : 'revert'}
        position={'relative'}
        dir={store.isRtl === 0 ? 'ltr' : 'rtl'}
        height={isLoading ? '100vh' : 'auto'}
      >
        {isLoadingPer && (
          <Stack width={'100vw'} zIndex={'200'} height={'100%'} top='0' position={'absolute'}>
            <Loading />
          </Stack>
        )}

        <Suspense fallback={<LayoutSplashScreen />}>
          <I18nProvider>
            {JwtService.getToken() ? (
              <LayoutProvider>
                <AuthInit>
                  <Outlet />
                  <MasterInit />
                </AuthInit>
                <ScrollToTop />
              </LayoutProvider>
            ) : (
              <LayoutProvider>
                <AuthInit>
                  <Login />
                  <MasterInit />
                </AuthInit>
                {/* <ScrollToTop /> */}
              </LayoutProvider>
            )}
          </I18nProvider>
        </Suspense>
      </Stack>
    </>
  )
}

export {App}
