import React from 'react'
import {useTranslation} from 'react-i18next'

export default function TableHead() {
  const {t} = useTranslation()

  const headTable = [
    {
      name: t('srNo'),
      class: 'min-w-80px',
    },
    {
      name: t('image'),
      class: 'min-w-100px',
    },
    {
      name: t('title'),
      class: 'min-w-180px',
    },
    {
      name: t('type'),
      class: 'min-w-580px ',
    },{
      name: t('section'),
      class: 'min-w-580px ',
    },
    {
      name: t('status'),
      class: 'min-w-540px ',
    },
    {
      name: t('actions'),
      class: 'max-w-520px',
    },
  ]

  return (
    <thead>
      <tr className='fw-bold text-muted'>
        {headTable.map((e, i) => (
          <th
            style={{
              textAlign: i === 0 ? 'start' : 'start',
              width: i === headTable.length - 1 || i === 0 ? '110px' : 'auto',
            }}
            key={i}
            className={e.class}
          >
            {e.name}
          </th>
        ))}
      </tr>
    </thead>
  )
}
