import {toast} from 'react-toastify'
import Apiservices from '../../lib/ApiServices'
import ApisMethods from './apisMethods'

const Product = {
  get: (callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/products/get/names`, callback, setIsLoading)
  },
  getItems: (
    searchText: string,
    rowsPerPage: number,
    page: number,
    callback: any,
    setIsLoading: any
  ) => {
    let url = `/vendor/products?page=${page}&per_page=${rowsPerPage}`
    if (searchText) {
      url = url + `&search=${searchText}`
    }
    ApisMethods().get(url, callback, setIsLoading)
  },
  put: (id: any, data: any, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/products/${id}`, data, callback, setIsLoading)
  },
  destroy: (id: number, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/products/${id}`, callback, setIsLoading)
  },
  getViewProduct: (id: number, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/products/view-product/${id}`, callback, setIsLoading)
  },
  getOption: (id: any, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/products-options?product_id=${id}`, callback, setIsLoading)
  },
  storeOption: (optionData: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/products-options`, optionData, callback, setIsLoading)
  },
  editOption: (id: number, optionData: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`/vendor/products-options/${id}`, optionData, callback, setIsLoading)
  },
  getOptionValue: (id: any, callback: any, setIsLoading: any) => {
    ApisMethods().get(`/vendor/options/${id}/get-option-details-names`, callback, setIsLoading)
  },
  destroyOptionProduct: (
    id: number,
    product_id: string | undefined,
    product_option_id: number,
    callback: any,
    setIsLoading: any
  ) => {
    ApisMethods().destroy(
      `/vendor/products-options/option-values/${id}?product_id=${product_id}&product_option_id=${product_option_id}`,
      callback,
      setIsLoading
    )
  },
  editOptionProduct: async (
    id: number,
    product_id: string | undefined,
    product_option_id: number,
    data: any,
    callback: any,
    setIsLoading: any
  ) => {
    ApisMethods().put(
      `/vendor/products-options/option-values/${id}`,
      {
        ...data,
        product_id: product_id,
        product_option_id: product_option_id,
      },
      callback,
      setIsLoading
    )
  },
  getSelectedOptions: async (
    id: number,
    product_id: string | undefined,
    callback: any,
    setIsLoading: any
  ) => {
    ApisMethods().get(
      `/vendor/products-options/get-options-details/${id}?product_id=${product_id}`,
      callback,
      setIsLoading
    )
  },
  destroyOptionProductById: async (
    id: number,
    product_id: string | undefined,
    callback: any,
    setIsLoading: any
  ) => {
    ApisMethods().destroy(
      `/vendor/products-options/${id}?product_id=${product_id}`,
      callback,
      setIsLoading
    )
  },
  addBulk: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().post(`vendor/products/bulk-discount`, data, callback, setIsLoading)
  },
  editBulk: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().put(`/vendor/products/bulk-discount/${data.id}`, data, callback, setIsLoading)
  },

  deleteBulk: (data: any, callback: any, setIsLoading: any) => {
    ApisMethods().destroy(`/vendor/products/bulk-discount/${data.id}`, callback, setIsLoading, data)
  },
}
export default Product
