import {Stack} from '@mui/material'
import React, {useState} from 'react'
import Label from '../customTable/Label'
import MainBtn from '../customTable/MainBtn'
import InputCode from '../login/InputCode'
import {useSelector} from 'react-redux'
import {RootState} from '../../store'
import PackagesApi from '../../Api/Packages'
import ValidationFun from '../../yup/ValidationFun'
import {VereficationSchema} from '../../yup/PackagesSchema'
import JwtService from '../../utils/TokenServices'

export default function Varefacation({price, name, isRenew, signature, id, type, setOpen}: any) {
  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  const [isLoading, setIsLoading] = useState(false)
  let isCode = ''
  const [codeValue, setCodeValue] = useState<any>({})
  const arrCode = ['a', 'b', 'c', 'd', 'e']
  const [isfocus, setFoucus] = useState('a')
  const [errors, setErrors] = useState<any>({})
  const submit = () => {
    if (isRenew) {
      ValidationFun(codeValue, VereficationSchema, setErrors, () => {
        PackagesApi.renewConfirm(
          {
            code: isCode,
            signature: signature,
          },
          () => {
            setOpen(false)
          },
          setIsLoading
        )
      })
    } else {
      ValidationFun(codeValue, VereficationSchema, setErrors, () => {
        PackagesApi.upgradeConfirm(
          {
            code: isCode,
            plan_id: id,
            signature: signature,
            plan_type: type,
          },
          () => {
            // setCodeValue({})
            setOpen(false)
          },
          setIsLoading
        )
      })
    }
  }

  for (const key in codeValue) {
    isCode += codeValue[key]
  }

  return (
    <Stack gap='24px' p='24px'>
      <Stack alignItems={'center'} gap='24px'>
        <Label fs='18px' fw='600' text='confirmCheckout' />

        <Label text={(name && (name[lang] || name[defLang])) || name} fs='18px' fw='600' />
        <Label
          text={`${price} ${localStorage.getItem('currency')} `}
          color='#65656A'
          fs='16px'
          fw='600'
        />
        <Label text='Enter the verification code' />
        <Stack>
          <Stack gap='20px' justifyContent={'center'} direction={'row'}>
            {['a', 'b', 'c', 'd', 'e'].map((e, i) => (
              <InputCode
                err={errors[e]}
                isfocus={isfocus}
                codeValue={codeValue}
                e={e}
                setCodeValue={setCodeValue}
                setFoucus={setFoucus}
                arrCode={arrCode}
              />
            ))}
          </Stack>
        </Stack>
        <Stack direction={'row'} gap='20px' justifyContent={'flex-end'}>
          <MainBtn width='auto' isLoading={isLoading} handelSubmit={submit} text='submit' />
          <MainBtn width='auto' without handelSubmit={() => setOpen(false)} text='cancel' />
        </Stack>
      </Stack>
    </Stack>
  )
}
