import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import TitlePage from '../customTable/TitlePage'
import {useTranslation} from 'react-i18next'
import Cards from './Cards'
import Msg from './Msg'
import CardsPacage from './CardsPacage'
import Backages from '../storeManagement/applicationMaker/Backages'
import BackageTable from './backageTable'
import SimpleLoading from '../customTable/SimpleLoading'

export default function ChangePackage({width}: any) {
  const {t} = useTranslation()

  const bannerInfo = {
    title: t('changePackage'),
    home: t('home'),
    linkHome: '/',
  }
  const [packageById, setPackageById] = useState(null)
  const [isLoadingTable, setIsLoadingTable] = useState(true)

  return (
    <Stack>
      <TitlePage info={bannerInfo} />
      <Stack position={'relative'} bgcolor={'#fff'} mt='32px'>
        <Backages
          setIsLoadingTable={setIsLoadingTable}
          setPackageById={setPackageById}
          width={width}
          title='changeBackage'
          subTitle='ChooseTheRightPackageForYou'
        />
        {isLoadingTable ? <SimpleLoading /> : <BackageTable packageById={packageById} />}
      </Stack>
    </Stack>
  )
}
