import React from 'react'
import MainBtn from '../../customTable/MainBtn'
import {Stack} from '@mui/material'
import PopUp from '../../customTable/PopUp'
import RowBtn from './RowBtn'

export default function RowBtns({colors, prices, duration, type, name}: any) {
  console.log(name, 'nameoi')

  return (
    <tr className='fw-bold text-muted '>
      <td></td>
      {Object.entries(colors).map(([key, value]: any, i) => {
        return (
          <td>
            <Stack alignItems={'center'}>
              <RowBtn
                name={name}
                type={type}
                duration={duration}
                prices={prices}
                value={value}
                index={key}
              />
            </Stack>
          </td>
        )
      })}
    </tr>
  )
}
