import {Stack} from '@mui/material'
import React, {useState, useEffect} from 'react'
import CardsImage from './CardsImage'
import Contant from './Contant'
import * as Yup from 'yup'

import RestSaveButton from '../../customTable/RestSaveBtns'
import GeneralApi from '../../../Api/SettingGeneral'
import {GeneralSettingValidation} from '../../../yup/SettingSchema'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {actions} from '../../../store/vender/Auth/auth.slice'
import {toast} from 'react-toastify'
import ErrorMsg from '../../customTable/ErrorMsg'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import ValidationFun from '../../../yup/ValidationFun'
import Loading from '../../customTable/LoadingForm'
export default function General() {
  const {t} = useTranslation()

  const [isLoading, setIsLoading] = useState(false)
  const [old, setOld] = useState({})
  const [setting, setSeeting] = useState<any>({
    header_logo: '',
    footer_logo: '',
    app_logo: '',
    identity_papers: '',
    title: {},
    mobile: '',
    description: {},
    color: '#0000',
  })
  console.log(setting, 'setting')

  const [msgErr, setMsgErr] = React.useState<string>('')
  const [isLoadingGeneral, setIsLoadingGeneral] = useState(false)

  const getSetting = () => {
    GeneralApi.get((res: any) => {
      setSeeting(res.data)

      setOld(res.data)
    }, setIsLoadingGeneral)
  }
  useEffect(() => {
    getSetting()
  }, [])
  const dispatch = useDispatch()
  const [languages, setlanguages] = useState(['en'])
  const store = useSelector((state: RootState) => state.vender)

  useEffect(() => {
    setlanguages(store.storeLangs)
  }, [store.storeLangs])
  const [errors, setErrors] = useState({})
  const handelSubmit = async () => {
    ValidationFun(setting, GeneralSettingValidation(languages), setErrors, () => {
      const formData = new FormData()
      if (setting.header_logo && typeof setting.header_logo !== 'string') {
        formData.append('header_logo', setting.header_logo)
      }
      if (setting.footer_logo && typeof setting.footer_logo !== 'string') {
        formData.append('footer_logo', setting.footer_logo)
      }
      if (setting.app_logo && typeof setting.app_logo !== 'string') {
        formData.append('app_logo', setting.app_logo)
      }
      if (setting.identity_papers && typeof setting.identity_papers !== 'string') {
        formData.append('identity_papers', setting.update_identity_papers)
      }
      for (const [key, value] of Object.entries(setting.title)) {
        if (typeof value === 'string') {
          formData.append(`title[${key}]`, value)
        }
      }
      for (const [key, value] of Object.entries(setting.description)) {
        if (typeof value === 'string') {
          formData.append(`description[${key}]`, value)
        }
      }
      formData.append('mobile', setting.mobile)
      formData.append('phone_code', setting.phone_code)
      formData.append('email', setting.email)
      formData.append('default_sku', setting.default_sku)
      formData.append('color', setting.color)
      formData.append('notification_key', setting.notification_key)
      GeneralApi.put(
        formData,
        (res: any) => {
          localStorage.setItem('storeImage', res.data.header_logo)
          dispatch(actions.getStoreImage(res.data.header_logo))

          setSeeting(res.data)
          setOld(res.data)
          setMsgErr('')
        },
        setIsLoading
      )
    })
  }
  return (
    <Stack minHeight={'60vh'} position={'relative'}>
      {isLoadingGeneral ? (
        <Loading />
      ) : (
        <Stack>
          <CardsImage setSeeting={setSeeting} setting={setting} />
          <Contant errors={errors} msgErr={msgErr} setSeeting={setSeeting} setting={setting} />
          <Stack>
            {store.permissions && store.permissions.includes('general_update') && (
              <RestSaveButton
                isLoading={isLoading}
                SaveText={t('save')}
                cancelText={t('rest')}
                submit={handelSubmit}
                cancel={() => {
                  setSeeting(old)
                }}
              />
            )}

            <ErrorMsg msgErr={msgErr} name='' />
          </Stack>
        </Stack>
      )}
    </Stack>
  )
}
