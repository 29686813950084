import * as Yup from 'yup'

export const AppMakerSchema = Yup.object().shape({
  account_mail: Yup.string().required('titleErr'),
  account_password: Yup.string().required('titleErr'),
  app_icon: Yup.string().required('titleErr'),
  is_agree: Yup.string().required('titleErr'),
  duns_num: Yup.string().when('active', {
    is: 1,
    then: Yup.string().required('imageErr'),
    otherwise: Yup.string(),
  }),
  working_email: Yup.string().when('active', {
    is: 0,
    then: Yup.string().required('imageErr'),
    otherwise: Yup.string(),
  }),
  commercial_file: Yup.string().when('active', {
    is: 0,
    then: Yup.string().required('imageErr'),
    otherwise: Yup.string(),
  }),
  passport: Yup.string().when('active', {
    is: 0,
    then: Yup.string().required('imageErr'),
    otherwise: Yup.string(),
  }),
  id_face_one: Yup.string()
    .nullable()
    .when(['active', 'check'], {
      is: (active: any, check: any) => active === 0 && check === 0, // الشرط للتحقق
      then: Yup.string().required('imageErr'), // إذا تحقق الشرط
      otherwise: Yup.string().nullable(), // الحقل غير مطلوب ويمكن أن يكون null
    }),
  id_face_two: Yup.string()
    .nullable()
    .when(['active', 'check'], {
      is: (active: any, check: any) => active === 0 && check === 0, // نفس الشرط للحقل الثاني
      then: Yup.string().required('imageErr'),
      otherwise: Yup.string().nullable(), // الحقل غير مطلوب ويمكن أن يكون null
    }),
})
