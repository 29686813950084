import {Stack} from '@mui/material'
import React, {useEffect, useState} from 'react'
import Tabs from './Tabs'
import IDoNotHaveAccountsInStoresForm from './IDoNotHaveAccountsInStoresForm'
import IHaveAccountsInStoresForm from './IHaveAccountsInStoresForm'
import IHaveAftterSubmit from './IHaveAftterSubmit'
import AppMaker from '../../../Api/AppMaker'
import Loading from '../../customTable/LoadingForm'

export default function ActiveForm({android}: any) {
  const [active, setActive] = useState(1)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(!false)
  const [data, setData] = useState({check: 1})
  useEffect(() => {
    setIsSubmit(false)
  }, [android])
  useEffect(() => {
    AppMaker.get(
      android,
      (res: any) => {
        setData({...res.data, check: res.data.vendor_type === 'resident' ? 0 : 1})
        setActive(res.data?.is_have_account ? 1 : 2)
        setIsSubmit(true)
      },
      setIsLoading
    )
  }, [android])
  return (
    <Stack width={'100%'} minHeight={'80vh'} position={'relative'}>
      {isLoading ? (
        <Loading />
      ) : (
        <Stack>
          {isSubmit && active === 1 ? (
            <IHaveAftterSubmit data={data} android={android} />
          ) : isSubmit && active !== 1 ? (
            <IHaveAftterSubmit data={data} android={android} IDontHave />
          ) : (
            <></>
          )}
          {!isSubmit && (
            <>
              {' '}
              <Tabs active={active} setActive={setActive} />
              {active !== 1 ? (
                <IDoNotHaveAccountsInStoresForm
                  data={data}
                  setData={setData}
                  android={android}
                  setIsSubmit={setIsSubmit}
                />
              ) : (
                <IHaveAccountsInStoresForm
                  data={data}
                  active={active}
                  setData={setData}
                  android={android}
                  setIsSubmit={setIsSubmit}
                />
              )}
            </>
          )}
        </Stack>
      )}
    </Stack>
  )
}
