import {Button, Stack, Tooltip} from '@mui/material'
import React, {useEffect} from 'react'
import {useState} from 'react'
import {useTranslation} from 'react-i18next'
import Label from './Label'
import StaticInputFile from './StaticInputFile'

export default function UploadImgBtn({
  base64Image,
  setBase64Image,
  name,
  data,
  setData,
  width,
  height,
  br,
  disabled,
  border,
  isPdf,
  convertToBusiness,
  note,
  text,
}: any) {
  const [image, setImage] = useState(base64Image || '')
  const {t} = useTranslation()
  console.log(data, name)

  const handleImageChange = (event: any) => {
    const file = event.target.files[0]
    if ((isPdf && file) || (file && file.type.includes('image/'))) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        setBase64Image(file)
        if (name && data && setData) {
          setData({...data, [name]: file})
        }
        setImage(reader.result as string)
      }
      reader.onerror = (error) => {}
    } else {
      setBase64Image(null)
      alert(t('Only jpeg , jpg , png and svg image files are accepted'))
    }
  }

  useEffect(() => {
    if (data && data[name] && data[name].type && data[name].type.includes('image/')) {
      const reader = new FileReader()
      reader.readAsDataURL(data[name])
      reader.onload = () => {
        setBase64Image(data[name])

        setImage(reader.result as string)
      }
      reader.onerror = (error) => {}
    }
  }, [])
  return (
    <>
      <Tooltip title={note || 'Only jpeg , jpg , png and svg image files are accepted'}>
        <Stack width={'100%'} gap='8px'>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Label text={text} />
          </Stack>
          <Stack
            sx={{
              width: '100%',
              height: !data[name] && convertToBusiness ? '48px' : height || '85px',
              borderRadius: br || '100px',
            }}
          >
            {' '}
            <Stack
              alignItems={'flex-end'}
              p='8px'
              borderRadius={'8px'}
              border={!image && !data[name] ? '1px solid #dfdfdf' : 'none'}
            >
              <Button
                component={'label'}
                sx={{
                  bgcolor: !image && !data[name] ? '#bdfaff' : '',
                  width: !image && !data[name] ? 'auto' : '100%',
                }}
              >
                {!image && !data[name] ? (
                  <>
                    <Label color='var(--main-color)' text='upload' />
                  </>
                ) : (
                  <StaticInputFile width='100%' />
                )}
                <input
                  hidden
                  // accept='.jpg, .jpeg, .png, .svg'
                  onChange={handleImageChange}
                  multiple
                  type='file'
                  disabled={disabled}
                />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Tooltip>
    </>
  )
}
