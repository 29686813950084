import React, {useState, useCallback, useEffect} from 'react'
import HeadTable from '../../customTable/HeadTable'
import TableHead from './TablHead'
import Row from './Row'
import Banner from '../../../Api/banner'
import {PaginationSection} from '../../customTable/pagnationSection'
import {CustomerListLoading} from '../../customer/order/custmerListLoading'
import {CircularProgress, Stack} from '@mui/material'
import IBanner from '../../../interface/IBanner'
import Manufacturers from '../../../Api/manufacturers'
import Search from '../../customTable/Search'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import RowBtns from './RowBtns'

export default function Table({rows, setRows, packageById}: any) {
  const [isLoading, setIsLoading] = useState(false)

  const [added, setAdded] = useState(false)
  const {t} = useTranslation()
  const [dataTable, setDataTable] = useState({})
  const [newDataTable, setNewDataTable] = useState<any>([])
  const [prices, setPrices] = useState({})
  const [duration, setDuration] = useState({})
  const [name, setName] = useState({})
  const [type, setType] = useState({})
  useEffect(() => {
    let item: any = {}
    packageById.map((e: any, i: any) => {
      for (const key in e) {
        if (key === 'features') {
          e.features.map((ele: any, index: any) => {
            for (const k in ele) {
              item = {
                ...item,
                [ele.feature_key]: {
                  ...item[ele.feature_key],
                  [`${i}`]: ele,
                  name: ele.feature_name,
                },
              }
            }
          })
        } else if (key === 'color' || key === 'name' || key === 'type') {
          item = {
            ...item,
            [key]: {
              ...item[key],
              [`${e.paid_id}`]: e[key],
            },
          }
        } else if (key === 'price') {
          item = {
            ...item,
            [key]: {
              ...item[key],
              [`${e.paid_id}`]: `${e[key]} ${localStorage.getItem('currency')}`,
            },
          }
        } else if (key === 'duration') {
          item = {
            ...item,
            [key]: {...item[key], [`${e.paid_id}`]: `${e[key]} ${e['duration_unit']}`},
          }
        }
      }
    })
    let arr: any = []

    for (const key in item) {
      if (key === 'duration') {
        setDuration(item['duration'])
      } else if (key === 'color') {
        setDataTable(item['color'])
      } else if (key === 'name') {
        setName(item['name'])
      } else if (key === 'type') {
        setType(item['type'])
      } else if (key === 'price') {
        setPrices(item['price'])
        arr = [...arr, {...item[key], name: item[key].name ? item[key].name : key}]
      } else {
        arr = [...arr, {...item[key], name: item[key].name ? item[key].name : key}]
      }
    }
    setNewDataTable(arr)
  }, [packageById])

  return (
    <div className={`card fds sha pt-5`}>
      {/* <button onClick={() => console.log(duration, name, prices)}>gg</button> */}
      <div className='card-body py-3 pb-5'>
        <div className='table-responsive overflow-auto  '>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <TableHead data={packageById.map((e: any) => e.name)} />
            {!added ? (
              <>
                {' '}
                {!isLoading ? (
                  <tbody className='mb-4'>
                    {newDataTable.map((e: any) => (
                      <Row setIsLoading={setIsLoading} setRows={setRows} rows={rows} item={e} />
                    ))}

                    <RowBtns
                      type={type}
                      name={name}
                      duration={duration}
                      prices={prices}
                      colors={dataTable}
                    />
                  </tbody>
                ) : (
                  <CircularProgress />
                )}
              </>
            ) : (
              <h1>loading</h1>
            )}
          </table>

          {/* pagnation  item   */}
        </div>
      </div>
    </div>
  )
}
