import React, {useState} from 'react'
import {ActionsCell} from '../../../componet/Menu'
import ICuopon from '../../../interface/ICuopon'
import Switch from '../../../componet/Toastr/Switch'
import Coupons from '../../../Api/coupons'
import {async} from 'q'
import EditCoupon from './EditCoupon'
import {RootState} from '../../../store'
import {useSelector} from 'react-redux'
import SimpleLoading from '../../customTable/SimpleLoading'

export default function Row({
  item,
  rows,
  setRows,
  setIsLoading,
  setNum,
  setAdded,
}: {
  item: ICuopon
  rows: any
  setRows: any
  setIsLoading: any
  setNum: any
  setAdded: any
}) {
  const [state, setState] = useState(item.status)
  const [isLoadingStatus, setIsLoadingStatus] = useState(false)
  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    Coupons.put(
      item.id,
      {
        status: state === 1 ? 0 : 1,
      },
      () => {
        setRows(
          [
            ...rows.filter((e: any) => e.id != item.id),
            {...edit, status: state === 1 ? 0 : 1},
          ].sort((a, b) => b.id - a.id)
        )
      },
      setIsLoadingStatus
    )

    setState(state === 1 ? 0 : 1)
  }
  const [isDeleted, setIsDeleted] = useState(false)
  const [isDeletedLoading, setIsDeletedLoading] = useState(false)
  const destroy = () => {
    setAdded(!true)
    Coupons.delete(
      item.id,
      () => {
        setRows((prev: any) => prev.filter((e: any) => e.id !== item.id))
        setAdded(true)

        setIsLoading(false)
      },
      setIsDeletedLoading
    )
    setNum((prev: any) => prev - 1)
  }
  const [cuopon, stCuopon] = useState({})

  const [edit, setEdit] = useState(item)
  const [open, setOpen] = React.useState(false)
  const [isLoading, setIsLoadingDetiles] = useState(false)
  const openFormEdit = async () => {
    setOpen(true)
    Coupons.getDitals(
      item.id,
      (res: any) => {
        console.log(res.data)

        stCuopon(res.data)
      },
      setIsLoadingDetiles
    )
  }
  const {lang, defLang, permissions} = useSelector((state: RootState) => state.vender)

  return (
    <>
      {!isDeleted && (
        <>
          <tr className='fw-bold text-muted '>
            <td>
              <a className='text-dark fw-bold text-hover-primary fs-6'>
                {edit.name &&
                  (edit.name[`${lang}`] || edit.name[`${defLang}`]) &&
                  (edit.name[`${lang}`] || edit.name[`${defLang}`]).slice(0, 15)}
                {edit.name &&
                  (edit.name[`${lang}`] || edit.name[`${defLang}`]) &&
                  (edit.name[lang] || edit.name[defLang]).length > 15 &&
                  '...'}
              </a>
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted  fs-7 fw-semibold'>{edit.code}</span>
            </td>{' '}
            <td style={{textAlign: 'start'}}>
              <span className='text-muted  fs-7 fw-semibold'>{edit.discount_type}</span>
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted  fs-7 fw-semibold'>{edit.start_date}</span>
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted  fs-7 fw-semibold'>{edit.end_date}</span>
            </td>
            <td className=''>
              {isLoadingStatus ? (
                <SimpleLoading />
              ) : (
                <Switch
                  name='status'
                  value={state === 1 ? true : false}
                  handleChange={
                    permissions && permissions.includes('coupons_update') && handleStatusChange
                  }
                />
              )}
            </td>
            <td style={{textAlign: 'start'}} className=''>
              {isDeletedLoading ? (
                <SimpleLoading />
              ) : (
                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                  <ActionsCell
                    onEdit={permissions && permissions.includes('coupons_update') && openFormEdit}
                    onDelete={permissions && permissions.includes('coupons_delete') && destroy}
                    onView={null}
                  />
                </div>
              )}
            </td>
          </tr>
          <EditCoupon
            rows={rows}
            setRows={setRows}
            edit={edit}
            setEdit={setEdit}
            cuopon={cuopon}
            open={open}
            setOpen={setOpen}
            isLoading={isLoading}
          />
        </>
      )}
    </>
  )
}
