import {Stack} from '@mui/material'
import React from 'react'
import MainBtn from '../../customTable/MainBtn'
import Label from '../../customTable/Label'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'

export default function PayForm({price, duration, isLoading, submit, name}: any) {
  const today = new Date()
  const formattedDate = today.toLocaleDateString()
  const {lang, defLang} = useSelector((state: RootState) => state.vender)
  console.log(name)

  return (
    <Stack p='24px'>
      <Stack gap='10px'>
        <Label text='checkout' fs='20px' fw='600' />
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Label text={name && (name[lang] || name[defLang])} fs='18px' fw='600' />
          <Label text={price} color='#65656A' fs='16px' fw='600' />
        </Stack>
        <Stack justifyContent={'space-between'} direction={'row'}>
          <Label text={`${formattedDate}`} color='#65656A' fs='16px' fw='600' />
          <Label text={duration} fs='16px' fw='600' />
        </Stack>
        <Stack pt='10px' gap='20px'>
          <Label align='center' text='payWith' fs='14px' fw='600' />
          <Stack alignItems={'center'}>
            <MainBtn handelSubmit={submit} isLoading={isLoading} width='auto' text='konin2pay' />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}
