import React, {useState, useCallback, useEffect} from 'react'
import HeadBar from './Head'
import HeadTable from './HeadTable'
import Row from './Row'
import Coupons from '../../../Api/coupons'
import {PaginationSection} from '../../customTable/pagnationSection'
import {CircularProgress} from '@mui/material'
import {useTranslation} from 'react-i18next'

export default function CouponsTable() {
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [rows, setRows] = useState([])
  const [timerId, setTimerId] = useState<number | null>(null)
  const [search, setSearch] = useState('')
  const [total, setTotal] = useState(1)
  const [num, setNum] = useState(0)
  const [added, setAdded] = useState(false)

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newSearchValue = e.target.value
      setSearch(e.target.value)
      if (timerId) {
        clearTimeout(timerId)
      }

      setTimerId(
        window.setTimeout(() => {
          getCoupons(newSearchValue, rowsPerPage, 1)
          setPage(1)
        }, 500)
      )
    },
    [timerId]
  )
  const getCoupons = (searchText: string, rowsPerPage: number, page: number) => {
    Coupons.get(
      searchText,
      rowsPerPage,
      page,
      (res: any) => {
        setTotal(res.pages.total)

        setRows(res.data)
      },
      setIsLoading
    )
  }
  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (newPrePage: number) => {
    setRowsPerPage(newPrePage)
    setPage(1)
  }
  useEffect(() => {
    getCoupons(search, rowsPerPage, page)
  }, [rowsPerPage, page])

  useEffect(() => {
    if (total) {
      setNum(total)
    }
  }, [total])
  useEffect(() => {
    if (added) {
      setAdded(false)
    }
  }, [rows])
  return (
    <div style={{margin: '31px 0'}} className={`card fds sha`}>
      <HeadBar
        setRows={setRows}
        total={rowsPerPage}
        rows={rows}
        search={search}
        handleSearch={handleSearch}
        setIsLoading={setIsLoading}
        num={num}
        setNum={setNum}
      />
      <div className='card-body py-3 pb-5'>
        <div className='table-responsive overflow-auto  '>
          <table
            // style={{minHeight: '500px'}}
            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
          >
            <HeadTable />
            {!added ? (
              <>
                {' '}
                {!isLoading ? (
                  <tbody className='mb-4'>
                    {rows.map((e, i) => (
                      <Row
                        setAdded={setAdded}
                        setNum={setNum}
                        setIsLoading={setIsLoading}
                        rows={rows}
                        setRows={setRows}
                        item={e}
                        key={i}
                      />
                    ))}
                  </tbody>
                ) : (
                  <CircularProgress />
                )}
              </>
            ) : (
              ''
            )}
          </table>
        </div>

        <PaginationSection
          rowsPerPageOptions={[5, 20, 30, 40, 50, 60]}
          count={total || 1}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        {/* {isLoading && <CustomerListLoading />} */}
      </div>
    </div>
  )
}
