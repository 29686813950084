import React, {useState} from 'react'
import {ActionsCell} from '../../../componet/Menu'
import {Box, Stack} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {RootState} from '../../../store'
import Switch from '../../../componet/Toastr/Switch'
import Media from '../../../Api/media'
import PageApi from '../../../Api/Page'
import {useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import SimpleLoading from '../../customTable/SimpleLoading'

export default function Row({item, i, setRows}: {item: any; i: number; setRows: any}) {
  const [state, setState] = useState(item.status)
  const [open, setOpen] = React.useState(false)
  const [editItem, setEditItem] = useState(item)
  const navigate = useNavigate()

  const handelEdit = () => {
    navigate(`/storeManagement/edit-page/${item.id}`)
  }
  const [isLoading, setIsLoading] = useState(false)
  const handleStatusChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    PageApi.putStatus(
      item.id,
      state === 1 ? 0 : 1,
      () => {
        setRows((prev: any) =>
          [
            ...prev.filter((e: any) => e.id != item.id),
            {...editItem, status: state === 1 ? 0 : 1},
          ].sort((a, b) => b.id - a.id)
        )
        setState(state === 1 ? 0 : 1)
      },
      setIsLoading
    )
  }
  const [isDeleted, setDeleted] = useState(true)
  const [isLoadingDeleted, setLoadingDeleted] = useState(!true)
  const handelDelete = () => {
    PageApi.delete(
      item.id,
      () => {
        setDeleted(false)
      },
      setLoadingDeleted
    )
  }
  const store = useSelector((state: RootState) => state.vender)

  return (
    <>
      {isDeleted && (
        <>
          {' '}
          <tr className='fw-bold text-muted '>
            <td>
              <a className='text-dark fw-bold text-hover-primary fs-6'>{i + 1}</a>
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>
                {editItem.title &&
                  (editItem.title[store.lang] || editItem.title[store.defLang]) &&
                  (editItem.title[store.lang] || editItem.title[store.defLang]).slice(0, 15)}
                {editItem.title &&
                  (editItem.title[store.lang] || editItem.title[store.defLang]) &&
                  (editItem.title[store.lang] || editItem.title[store.defLang]).length > 15 &&
                  '...'}
              </span>{' '}
            </td>
            <td style={{textAlign: 'start'}}>
              <span className='text-muted me-2 fs-7 fw-semibold'>{editItem.type}</span>{' '}
            </td>
            <td style={{textAlign: 'start'}} className='text-start'>
              {isLoading ? (
                <SimpleLoading />
              ) : (
                <Switch
                  name='status'
                  value={state === 1 ? true : false}
                  handleChange={
                    store.permissions &&
                    store.permissions.includes('pages_update') &&
                    handleStatusChange
                  }
                />
              )}
            </td>
            <td style={{textAlign: 'start'}} className='text-start'>
              {isLoadingDeleted ? (
                <SimpleLoading />
              ) : (
                <ActionsCell
                  onEdit={
                    store.permissions && store.permissions.includes('pages_update') && handelEdit
                  }
                  onDelete={
                    store.permissions && store.permissions.includes('pages_delete') && handelDelete
                  }
                  onView={null}
                />
              )}
            </td>
          </tr>
        </>
      )}
    </>
  )
}
